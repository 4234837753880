import { Formik, FormikErrors } from 'formik'
import React, { Component } from 'react'
import { View } from 'react-native'

import Button from '../components/Button'
import Label from '../components/Label'
import Screen from '../components/Screen'
import TextField from '../components/TextField'
import color from '../utils/color'

interface FormValues {
  email: string
}

interface Props {
  onSubmit: (values: FormValues) => void
  validate: (values: FormValues) => FormikErrors<FormValues>
  loading: boolean
}
class PasswordRecovery extends Component<Props> {
  public render() {
    return (
      <Screen>
        <Label weight="medium" size={13} style={{ color: color.text.input }}>
          Informe seu e-mail e siga as instruções recebidas
        </Label>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={(values: FormValues) => this.props.onSubmit(values)}
          validate={this.props.validate}
        >
          {(formProps: any) => (
            <View style={{ marginTop: 10 }}>
              <TextField
                label="Email"
                maxLength={120}
                placeholder="login@example.com"
                textType="email"
                value={formProps.values.email}
                onChangeText={formProps.handleChange('email')}
                error={formProps.errors.email}
                testID="recover-email"
              />
              <View style={{ marginVertical: 0 }}>
                <Button
                  title="RECUPERAR SENHA"
                  onPress={formProps.submitForm}
                  disabled={this.isSubmitDisabled(formProps.values, formProps.errors)}
                  loading={this.props.loading}
                  round
                  testID="recover-password-button"
                />
              </View>
            </View>
          )}
        </Formik>
      </Screen>
    )
  }

  private isSubmitDisabled(values: FormValues, errors: FormikErrors<FormValues>) {
    return values.email === '' || this.props.loading === true || Object.keys(errors).length !== 0
  }
}

export default PasswordRecovery
