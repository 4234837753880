import isPromise from 'is-promise'
import { Dispatch, Middleware, MiddlewareAPI } from 'redux'

import { PayloadAction } from '../ducks/Duck'

const handlePromiseError: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (
  action: PayloadAction<any>
) => {
  if (!isPromise(action.payload)) {
    return next(action)
  }

  return (next(action) as any).catch((error: any) => {
    if (!error.isAxiosError) {
      throw error
    }
  })
}

export default handlePromiseError
