import React, { Component } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

import color from '../utils/color'
import testID from '../utils/testID'

interface Props {
  children: React.ReactNode
  style?: ViewStyle
  testID?: string
}
class Screen extends Component<Props> {
  public render() {
    return (
      <View {...testID('screen')} style={{ flex: 1 }}>
        <View style={[styles.container, this.props.style]} {...testID(this.props.testID)}>
          {this.props.children}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: '7%',
    paddingVertical: '5%',
    backgroundColor: color.background
  }
})

export default Screen
