import React, { Component, ComponentType } from 'react'
import { View } from 'react-native'

import MainGradient from './MainGradient'

export function withGradientHeader<Props>(HeaderComponent: ComponentType<Props>) {
  return class extends Component<Props> {
    public render() {
      return (
        <View>
          <MainGradient>
            <HeaderComponent {...this.props} />
          </MainGradient>
        </View>
      )
    }
  }
}
