import { FormikErrors } from 'formik'
import React, { Component } from 'react'
import { NavigationScreenProp } from 'react-navigation'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { AuthenticationDuck, authenticationDuck, Status } from '../ducks/authenticationDuck'
import { DuckActions } from '../ducks/Duck'
import { ApplicationState } from '../store'
import { passwordMatch } from '../utils/formValidation'
import NewPasswordWeb from './NewPasswordWeb'

const mapStateToProps = (store: ApplicationState) => ({
  status: store.authentication.status,
  errors: store.authentication.errors
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(authenticationDuck.creators(), dispatch)
})

interface Props {
  navigation: NavigationScreenProp<any, any>
  actions: DuckActions<AuthenticationDuck>
  status: Status
  errors: { [k: string]: string[] }
}

export interface FormValues {
  password: string
  confirmationPassword: string
}

interface State {
  loading: boolean
  success: boolean
  error: boolean
}

class NewPasswordContainer extends Component<Props, State> {
  public static navigationOptions = {
    header: null
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      success: false,
      error: false
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.status !== Status.Fulfilled && this.props.status === Status.Fulfilled) {
      this.setState({ loading: false })
      this.handleFulfilled()
    }
    if (prevProps.status !== Status.Pending && this.props.status === Status.Pending) {
      this.setState({ loading: true })
    }
    if (prevProps.status !== Status.Rejected && this.props.status === Status.Rejected) {
      this.setState({ loading: false })
      this.handleErrors()
    }
  }

  public render() {
    return (
      <NewPasswordWeb
        onSubmit={this.handleSubmit.bind(this)}
        loading={this.state.loading}
        success={this.state.success}
        error={this.state.error}
        validate={this.validate.bind(this)}
      />
    )
  }

  private getTokenParam() {
    return new URL(window.location.toString()).searchParams.get('token')
  }

  private handleSubmit(values: FormValues) {
    this.props.actions.newPassword(
      this.getTokenParam()!,
      values.password,
      values.confirmationPassword
    )
  }

  private handleFulfilled() {
    this.setState({ success: true })
  }

  private handleErrors() {
    this.setState({ error: true })
  }

  private validate(values: FormValues): FormikErrors<FormValues> {
    const errors: FormikErrors<FormValues> = {}

    if (
      !passwordMatch.check(values.password, values.confirmationPassword) &&
      values.confirmationPassword !== ''
    ) {
      errors.confirmationPassword = passwordMatch.message
    }

    return errors
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordContainer)
