export const emailValidation = {
  check: (email: string): boolean =>
    email.length === 0 || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email),
  message: 'E-mail inválido.'
}

export const passwordMatch = {
  check: (password: string, passwordConfirmation: string): boolean =>
    password === passwordConfirmation,
  message: 'Senhas não coincidem'
}

export const idDocumentValidation = {
  check: (idDocument: string): boolean =>
    idDocument.length === 0 || idDocument.length === 11 || idDocument.length === 14,
  message: 'CPF ou CNPJ inválido.'
}
