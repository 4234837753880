// app entry
import { AppRegistry, Platform } from 'react-native'

import AppRoot from './AppRoot'
import * as Sentry from './sentry'
import defineMomentLocale_PT_BR from './utils/moment.locale.pt-br'

Sentry.init({
  dsn: 'https://6481d26d1a7a4853a59f7fa93cd0a671@sentry.io/1852492',
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production'
})

// register the app
AppRegistry.registerComponent('radar', () => AppRoot)

// register the web
if (Platform.OS === 'web') {
  AppRegistry.runApplication('radar', {
    rootTag: document.getElementById('root')
  })
}

// define pt-br locale for moment.js
defineMomentLocale_PT_BR()
