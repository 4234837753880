import React, { Component } from 'react'
import LinearGradient from 'react-native-web-linear-gradient'

import color from '../utils/color'

interface Props {
  children: React.ReactNode
}

class MainGradient extends Component<Props> {
  public render() {
    return (
      <LinearGradient
        colors={[color.primary, color.primaryGradient]}
        useAngle={true}
        angle={45}
        angleCenter={{ x: 0.5, y: 0.5 }}
        start={{ x: 0, y: 1 }}
        end={{ x: 1, y: 1 }}
      >
        {this.props.children}
      </LinearGradient>
    )
  }
}

export default MainGradient
