import axios from 'axios'

import { config } from './utils/config'

export const options = {
  baseURL: `${config('API_URL')}/api/v1`,
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
}

export default axios.create(options)
