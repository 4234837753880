import React, { Component } from 'react'
import { StyleSheet, Text, TextStyle } from 'react-native'

import color from '../utils/color'
import { fontWeight, Weight } from '../utils/font'
import { moderateScale } from '../utils/scaler'
import testID from '../utils/testID'

interface Props {
  children: string | string[]
  size?: number
  align?: 'left' | 'center' | 'right'
  weight?: 'medium' | 'semibold' | 'bold'
  style?: TextStyle
  testID?: string
}

class Label extends Component<Props> {
  public render() {
    return (
      <Text
        style={[styles.text, this.propStyles(), this.props.style || []]}
        {...testID(this.props.testID)}
      >
        {this.props.children}
      </Text>
    )
  }
  private propStyles(): TextStyle {
    return {
      fontSize: this.props.size || moderateScale(12),
      textAlign: this.props.align || 'center',
      ...fontWeight(this.fontWeight())
    }
  }
  private fontWeight(): Weight {
    const { weight } = this.props
    return weight === 'bold'
      ? Weight.Bold
      : this.props.weight === 'medium'
      ? Weight.Medium
      : Weight.SemiBold
  }
}

const styles = StyleSheet.create({
  text: {
    color: color.primary,
    fontSize: moderateScale(12),
    textAlign: 'center',
    marginBottom: '7%'
  }
})

export default Label
