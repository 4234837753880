import * as Sentry from '@sentry/browser'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import promise from 'redux-promise-middleware'
import createSentryMiddleware from 'redux-sentry-middleware'
import thunk from 'redux-thunk'

import { authenticationDuck, AuthState } from './ducks/authenticationDuck'
import auth from './utils/authMiddleware'
import handlePromiseError from './utils/handlePromiseErrorMiddleware'

export interface Profile {
  name: string
}

export interface ApplicationState {
  authentication: AuthState
}

const reducer = combineReducers<ApplicationState>({
  authentication: authenticationDuck.REDUCER,
})

export default createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(
      auth,
      thunk,
      handlePromiseError,
      promise,
      createSentryMiddleware(Sentry, {})
    )
  )
)
