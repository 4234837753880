import { Platform, TextStyle } from 'react-native'

export enum Weight {
  Light = 'Light',
  Regular = 'Regular',
  Medium = 'Medium',
  SemiBold = 'SemiBold',
  Bold = 'Bold'
}

type StylesheetWeight =
  | 'bold'
  | '400'
  | '500'
  | '600'
  | '700'
  | 'normal'
  | '100'
  | '200'
  | '300'
  | '800'
  | '900'
  | undefined

const font = (name: string, weight: Weight): TextStyle => {
  if (Platform.OS === 'web') {
    return { fontFamily: name, fontWeight: weightValue(weight) }
  } else {
    return { fontFamily: mobileFontFamily(name, weight) }
  }
}

const mobileFontFamily = (name: string, weight: Weight) => {
  return name + '-' + weight
}

export const fontWeight = (weight: Weight): TextStyle => {
  switch (weight) {
    case Weight.Light:
      return { fontWeight: '100' }
    case Weight.Regular:
      return { fontWeight: '400' }
    case Weight.Medium:
      return { fontWeight: '500' }
    case Weight.SemiBold:
      return { fontWeight: '600' }
    case Weight.Bold:
      if (Platform.OS === 'android') {
        return { fontFamily: 'Roboto-Bold' }
      } else {
        return { fontWeight: '700' }
      }
  }
}

export const weightValue = (weight: Weight): StylesheetWeight => {
  switch (weight) {
    case Weight.Regular:
      return '400'
    case Weight.Medium:
      return '500'
    case Weight.SemiBold:
      return '600'
    case Weight.Bold:
      return '700'
    default:
      return '300'
  }
}
export default font
