import { Formik, FormikErrors } from 'formik'
import React, { Component } from 'react'
import { Platform, View } from 'react-native'

import Button from '../components/Button'
import TextField from '../components/TextField'
import { FormValues } from './NewPasswordContainer'

interface Props {
  validate: (values: FormValues) => void
  onSubmit: (values: FormValues) => void
  loading: boolean
}

class NewPasswordForm extends Component<Props> {
  public render() {
    return (
      <Formik
        initialValues={{ password: '', confirmationPassword: '' }}
        onSubmit={(values: FormValues) => this.props.onSubmit(values)}
        validate={this.props.validate}
      >
        {(formProps: any) => (
          <View style={{ marginTop: 10 }}>
            <TextField
              maxLength={25}
              label="Nova senha"
              placeholder="******"
              textType="password"
              value={formProps.values.password}
              onChangeText={formProps.handleChange('password')}
              error={formProps.errors.password}
              tip={this.passwordTip(formProps.values.password)}
              testID="password"
            />
            <View style={{ marginVertical: Platform.OS === 'web' ? 30 : 0 }}>
              <TextField
                maxLength={25}
                label="Confirme a nova senha"
                placeholder="******"
                textType="password"
                value={formProps.values.confirmationPassword}
                onChangeText={formProps.handleChange('confirmationPassword')}
                error={formProps.errors.confirmationPassword}
                testID="confirmation-password"
              />
            </View>
            <View style={{ marginVertical: 0 }}>
              <Button
                title="REDEFINIR SENHA"
                onPress={formProps.submitForm}
                disabled={this.isSubmitDisabled(formProps.values, formProps.errors)}
                loading={this.props.loading}
                round
                testID="new-password-button"
              />
            </View>
          </View>
        )}
      </Formik>
    )
  }

  private passwordTip(password: string) {
    const minimum = 6
    return password.length < minimum ? `Mínimo de ${minimum} caracteres` : undefined
  }

  private isSubmitDisabled(values: FormValues, errors: FormikErrors<FormValues>) {
    return (
      values.password.length < 6 ||
      values.confirmationPassword.length < 6 ||
      this.props.loading === true ||
      Object.keys(errors).length !== 0
    )
  }
}

export default NewPasswordForm
