import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { isArray } from 'lodash'
import React, { Component } from 'react'
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableHighlight,
  View,
  ViewStyle,
} from 'react-native'

import color, { fromColorScheme } from '../utils/color'
import font, { fontWeight, Weight } from '../utils/font'
import { moderateScale } from '../utils/scaler'
import testID from '../utils/testID'

interface Props {
  title?: string
  link?: boolean
  round?: boolean
  disabled?: boolean
  loading?: boolean
  alternative?: boolean
  circle?: boolean
  narrow?: boolean
  icon?: 'arrow' | 'check'
  colorScheme?: 'primary' | 'secondary' | 'third'
  touchableStyle?: ViewStyle | ViewStyle[]
  textStyle?: TextStyle | TextStyle[]
  underlayColor?: string
  activeOpacity?: number
  disabledWhenLoading?: boolean
  onPress: () => void
  testID?: string
}

class Button extends Component<Props> {
  public render(): React.ReactNode {
    return (
      <TouchableHighlight
        onPress={this.props.onPress}
        style={this.touchableStyle()}
        disabled={this.isPressDisabled()}
        underlayColor={this.underlayColor()}
        activeOpacity={this.props.activeOpacity}
        onPressIn={Keyboard.dismiss}
        {...testID(this.props.testID)}
      >
        {this.renderContent()}
      </TouchableHighlight>
    )
  }

  private renderContent(): React.ReactNode {
    if (this.props.loading) {
      if (!this.props.circle) {
        return Platform.OS === 'ios' ? (
          <View style={{ flexDirection: 'row', paddingVertical: '2%' }}>
            <Text style={[this.textStyle(), { paddingTop: 0, paddingLeft: 0 }]} />
            <ActivityIndicator
              style={{ paddingBottom: '2%', paddingLeft: 0, alignSelf: 'center' }}
              size={'small'}
              color={this.props.alternative ? color.primary : color.background}
            />
          </View>
        ) : (
          <ActivityIndicator
            style={{ paddingTop: 3 }}
            size={moderateScale(20)}
            color={this.props.alternative ? color.primary : color.background}
          />
        )
      } else {
        return (
          <ActivityIndicator
            style={this.textStyle()}
            size={moderateScale(28)}
            color={this.props.alternative ? color.primary : color.background}
          />
        )
      }
    } else if (this.props.circle) {
      return <View style={[this.textStyle()]}>{this.circleImage()}</View>
    } else {
      return (
        <Text
          style={[
            this.textStyle(),
            Platform.OS === 'ios' && !this.props.narrow && !this.props.link
              ? { paddingVertical: '3%' }
              : { paddingTop: 0 }
          ]}
        >
          {this.props.title}
        </Text>
      )
    }
  }

  private circleImage() {
    if (this.props.icon === 'check') {
      return <FontAwesomeIcon icon={faCheck} size={moderateScale(30)} color={color.background} />
    }
    return <Image source={require('../assets/images/arrow.png')} />
  }

  private isPressDisabled(): boolean {
    const { disabled, loading } = this.props
    const disabledWhenLoading =
      this.props.disabledWhenLoading === undefined ? true : this.props.disabledWhenLoading
    return !!(disabled || (disabledWhenLoading && loading))
  }

  private underlayColor(): string {
    const { underlayColor, link, colorScheme } = this.props
    if (underlayColor) {
      return underlayColor
    }
    if (link) {
      return 'transparent'
    }
    if (colorScheme === undefined) {
      return color.primaryDark
    } else {
      return color[`${this.props.colorScheme}Dark` as 'primaryDark' | 'secondaryDark' | 'thirdDark']
    }
  }

  private touchableStyle(): ViewStyle {
    const styleCollection: ViewStyle[] = [
      styles.touchable,
      { backgroundColor: fromColorScheme(this.props.colorScheme) }
    ]
    if (this.props.narrow) {
      styleCollection.push(styles.touchableNarrow)
    }
    if (this.props.alternative) {
      styleCollection.push(styles.touchableAlternative, {
        borderColor: fromColorScheme(this.props.colorScheme)
      })
    }
    if (this.props.circle) {
      styleCollection.push(styles.touchableCircle, {
        borderColor: fromColorScheme(this.props.colorScheme)
      })
    }
    if (this.props.link) {
      styleCollection.push(styles.touchableLink)
    }
    if (this.props.round) {
      styleCollection.push(styles.touchableRound)
    }

    if (this.props.disabled && this.props.alternative) {
      styleCollection.push(styles.touchableDisabledAlternative)
    } else if (this.props.disabled && this.props.link) {
      styleCollection.push(styles.touchableDisabledLink)
    } else if (this.props.disabled) {
      styleCollection.push(styles.touchableDisabled)
    }

    const { touchableStyle } = this.props
    if (touchableStyle) {
      const styleArray = isArray(touchableStyle) ? touchableStyle : [touchableStyle]
      styleCollection.push(...styleArray)
    }

    return StyleSheet.flatten(styleCollection)
  }

  private textStyle(): TextStyle {
    const styleCollection: TextStyle[] = [styles.text]

    if (this.props.narrow) {
      styleCollection.push(styles.textNarrow)
    }
    if (Platform.OS === 'ios') {
      styleCollection.push(styles.textNarrowiOS)
    }
    if (this.props.alternative) {
      styleCollection.push({ color: fromColorScheme(this.props.colorScheme) })
    }
    if (this.props.circle) {
      styleCollection.push(styles.textCircle)
    }
    if (this.props.link) {
      styleCollection.push(styles.textLink)
    }
    if (this.isPressDisabled()) {
      styleCollection.push(styles.textDisabled)
    }
    if (this.isPressDisabled() && this.props.link) {
      styleCollection.push(styles.textDisabledLink)
    }

    const { textStyle } = this.props
    if (textStyle) {
      const styleArray = isArray(textStyle) ? textStyle : [textStyle]
      styleCollection.push(...styleArray)
    }

    return StyleSheet.flatten(styleCollection)
  }
}

const styles = StyleSheet.create({
  touchable: {
    borderRadius: 5,
    elevation: 0,
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: Platform.OS === 'ios' ? '2%' : '4%'
  },
  touchableNarrow: {
    width: '70%',
    marginTop: '3%',
    paddingVertical: '5%',
    borderRadius: 11
  },
  touchableAlternative: {
    backgroundColor: 'transparent',
    marginVertical: '2%',
    borderWidth: 2
  },
  touchableCircle: {
    backgroundColor: color.primary,
    borderRadius: 45,
    marginVertical: '2%',
    width: moderateScale(55),
    aspectRatio: undefined,
    height: moderateScale(55),
    alignSelf: 'center',
    paddingVertical: '-1%'
  },
  touchableLink: {
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
    marginVertical: Platform.OS === 'ios' ? '0%' : '3%',
    backgroundColor: 'transparent',
    alignSelf: 'center'
  },
  touchableDisabled: {
    backgroundColor: color.disabled.button
  },
  touchableDisabledAlternative: {
    borderColor: color.disabled.button
  },
  touchableDisabledLink: {
    borderColor: 'transparent'
  },
  touchableRound: {
    borderRadius: 45
  },
  text: {
    ...fontWeight(Weight.Bold),
    color: color.text.touchableButton,
    fontSize: moderateScale(14),
    textAlign: 'center',
    alignSelf: 'center'
  },
  textLink: {
    ...fontWeight(Weight.Medium),
    color: color.text.link,
    textAlign: 'center',
    alignSelf: 'center',
    paddingVertical: Platform.OS === 'ios' ? '4%' : '1%',
    fontSize: moderateScale(14),
    textDecorationLine: 'none',
    textDecorationColor: color.background,
    textDecorationStyle: 'solid'
  },
  textDisabled: {
    color: color.disabled.text
  },
  textNarrow: {
    ...font('Roboto', Weight.Medium),
    fontSize: moderateScale(14),
    textAlign: 'center'
  },
  textNarrowiOS: {
    textAlign: 'center'
  },
  textCircle: {
    color: color.background,
    height: '85%',
    justifyContent: 'center'
  },
  textDisabledLink: {
    color: '#bbb'
  }
})

export default Button
