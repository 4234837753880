import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React, { Component, ComponentType } from 'react'
import { ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { NavigationScreenOptions } from 'react-navigation'

import color from '../utils/color'
import { fontWeight, Weight } from '../utils/font'
import { moderateScale } from '../utils/scaler'
import { withGradientHeader } from './withGradientHeader'

// import { withRouter, RouteComponentProps } from 'react-router-dom'

const GradientHeaderContent = ({
  title,
  isLoading
}: {
  title: string | undefined
  isLoading?: boolean
}) => (
  <View
    style={{
      backgroundColor: 'transparent',
      height: 80,
      paddingTop: 30,
      paddingBottom: 10
    }}
  >
    <Text
      style={{
        color: color.primary,
        ...fontWeight(Weight.SemiBold),
        fontSize: moderateScale(15),
        textAlign: 'center',
        alignSelf: 'center',
        flexGrow: 1,
        padding: 3
      }}
    >
      {title}
      {isLoading && (
        <ActivityIndicator
          style={{
            position: 'absolute',
            right: 10,
            top: 35,
            width: 20,
            height: 20
          }}
          color={color.primary}
        />
      )}
    </Text>
  </View>
)

const GradientNavigationHeader = withGradientHeader<{
  title: string | undefined
  isLoading?: boolean
}>(GradientHeaderContent)

interface ExtendedNavigationOptions {
  gradientHeader: boolean
  isLoading: boolean
}

export function withWebNavigationBar<P>(WrappedComponent: ComponentType<P>) {
  return class Wrapper extends Component<P> {
    public static navigationOptions: NavigationScreenOptions &
      ExtendedNavigationOptions = (WrappedComponent as any).navigationOptions

    public render() {
      return (
        <View style={{ flex: 1 }}>
          {Platform.OS === 'web' && this.renderNavigationBar()}
          <WrappedComponent {...this.props as P} />
        </View>
      )
    }

    private renderNavigationBar() {
      if (this.isHeaderDisabled()) {
        return null
      }

      if (Wrapper.navigationOptions.gradientHeader) {
        return (
          <GradientNavigationHeader
            title={Wrapper.navigationOptions.title}
            isLoading={!!Wrapper.navigationOptions.isLoading}
          />
        )
      }

      return (
        <View style={styles.main}>
          {this.renderLeftHeader()}
          {this.renderMainHeader()}
          {this.renderRightHeader()}
        </View>
      )
    }

    private renderLeftHeader() {
      if (Wrapper.navigationOptions.headerLeft !== undefined) {
        return Wrapper.navigationOptions.headerLeft
      } else {
        return this.renderBackButton()
      }
    }

    private renderRightHeader() {
      if (Wrapper.navigationOptions.headerRight !== undefined) {
        return Wrapper.navigationOptions.headerRight
      } else {
        return <View />
      }
    }

    private renderMainHeader() {
      if (Wrapper.navigationOptions.gradientHeader) {
        return <GradientNavigationHeader title={Wrapper.navigationOptions.title} />
      }

      return (
        <View>
          <Text style={styles.title}>{Wrapper.navigationOptions.title}</Text>
        </View>
      )
    }

    private renderBackButton() {
      return (
        <TouchableOpacity
          onPress={() => (this.props as any).navigation.goBack()}
          style={{ marginTop: 3 }}
        >
          <View>
            <FontAwesomeIcon icon={faChevronLeft} color={color.primary} />
          </View>
        </TouchableOpacity>
      )
    }

    private isHeaderDisabled() {
      return Wrapper.navigationOptions === undefined || Wrapper.navigationOptions.header === null
    }
  }
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: '7%',
    paddingVertical: 15,
    backgroundColor: color.background
  },
  title: {
    color: color.primary,
    ...fontWeight(Weight.SemiBold),
    fontSize: 18
  }
})
