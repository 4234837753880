/*
 * https://github.com/inspmoore/rnw_boilerplate_nav
 *
 */
import React, { ComponentType } from 'react'
import { Route } from 'react-router-dom'
import { ModalRoute } from 'react-router-modal'

import { withWebNavigationBar } from '../components/withWebNavigationBar'

// import 'react-router-modal/css/react-router-modal.css'

interface RouteMap {
  [to: string]: {
    component: ComponentType<any>
    path: string
    exact: boolean
    modal?: boolean
  }
}

interface WrapperProps {
  element: JSX.Element
  history: { push: (path: string) => void; goBack: () => void }
  routeMap: RouteMap
  match: { url: string; params: { [key: string]: any } }
  closeModal?: () => void
}

function Wrapper({ element, history, match, routeMap, closeModal }: WrapperProps) {
  const navigate = (to: string, params: { [key: string]: any }) => {
    let url = routeMap[to].path
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`:${param}\\??`)
        url = url.replace(re, escape(params[param]))
      })
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, '')
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url)
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? '' : '/'
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url
      // removing the */ from the url
      url = url.replace(/\*\/?/g, '')
      history.push(url)
    }
  }

  const getParam = (param: string, alternative: any) => {
    return match.params[param] || alternative
  }

  const goBack = () => {
    history.goBack()
  }

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  })
}

interface GeneratorProps {
  routeMap: RouteMap
}

const WebRoutesGenerator = ({ routeMap }: GeneratorProps) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route]
    const WrappedComponent = withWebNavigationBar<any>(currentRoute.component)
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={(props: any) => (
            <Wrapper element={<WrappedComponent />} {...props} routeMap={routeMap} />
          )}
        />
      )
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Wrapper element={<WrappedComponent />} {...props} routeMap={routeMap} />
          )}
        />
      )
    }
  })
}

export default WebRoutesGenerator
