import React, { Component, ComponentType } from 'react'
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import { Subtract } from 'utility-types'

import color from '../utils/color'
import { fontWeight, Weight } from '../utils/font'
import { moderateScale } from '../utils/scaler'
import testID from '../utils/testID'

export interface FieldProps {
  label: string
  alternative?: boolean
  tip?: string
  error?: string
  disabled?: boolean
}

export interface InjectedProps {
  active: boolean
  errorTipVisible: boolean
  setActive: (active: boolean) => void
}

interface State {
  active: boolean
}

export function withField<P extends InjectedProps>(Field: ComponentType<P>) {
  return class extends Component<Subtract<P, InjectedProps> & FieldProps, State> {
    constructor(props: Subtract<P, InjectedProps> & FieldProps) {
      super(props)
      this.state = { active: false }
    }

    public render() {
      return (
        <View>
          <Text style={this.mainLabelStyle()} {...testID('input-label')}>
            {this.props.label}
          </Text>
          <View style={this.fieldContainerStyle()}>
            <Field
              active={this.state.active}
              errorTipVisible={this.hasError()}
              setActive={this.setActive.bind(this)}
              {...this.props as P & FieldProps}
            />
          </View>
          {this.renderTipLabel()}
        </View>
      )
    }

    private setActive(active: boolean) {
      this.setState({ active })
    }

    private renderTipLabel() {
      if (this.hasError()) {
        return (
          <Text style={styles.errorLabel} {...testID('field-error')}>
            {this.props.error}
          </Text>
        )
      } else {
        return <Text style={this.tipLabelStyle()}>{this.props.tip}</Text>
      }
    }

    private hasError() {
      return !this.state.active && this.props.error
    }

    private mainLabelStyle() {
      const styleCollection: TextStyle[] = [styles.mainLabel]

      if (this.hasError()) {
        styleCollection.push(styles.mainLabelError)
      }
      if (this.props.disabled) {
        styleCollection.push(styles.mainLabelDisabled)
      }

      return StyleSheet.flatten(styleCollection)
    }

    private fieldContainerStyle() {
      const styleCollection: ViewStyle[] = [styles.fieldContainer]
      if (this.props.alternative) {
        styleCollection.push(styles.alternativeContainer)
      }

      if (this.state.active) {
        styleCollection.push(styles.fieldContainerActive)
      }
      if (this.hasError()) {
        styleCollection.push(styles.fieldContainerError)
      }

      return StyleSheet.flatten(styleCollection)
    }

    private tipLabelStyle() {
      const styleCollection: TextStyle[] = [styles.tipLabel]

      if (this.state.active) {
        styleCollection.push(styles.tipLabelActive)
      }
      if (this.props.disabled) {
        styleCollection.push(styles.tipLabel)
      }

      return StyleSheet.flatten(styleCollection)
    }
  }
}

const styles = StyleSheet.create({
  alternativeContainer: {
    backgroundColor: color.field.background,
    borderBottomColor: color.field.border,
    borderBottomWidth: 1,
    borderWidth: 1,
    paddingVertical: '2%',
    borderRadius: 7,
    borderColor: color.field.border
  },
  mainLabel: {
    color: color.primary,
    ...fontWeight(Weight.SemiBold),
    fontSize: moderateScale(14)
  },
  mainLabelError: {
    color: color.error
  },
  mainLabelDisabled: {
    color: color.text.gray
  },
  fieldContainer: {
    marginTop: '2%',
    borderBottomWidth: 3,
    borderBottomColor: color.inactiveFieldBorder
  },
  fieldContainerActive: {
    borderBottomColor: color.primary
  },
  fieldContainerError: {
    borderBottomColor: color.error
  },
  tipLabel: {
    color: color.text.gray,
    ...fontWeight(Weight.Medium),
    fontSize: moderateScale(12),
    paddingTop: 5
  },
  tipLabelActive: {
    color: color.primary,
    ...fontWeight(Weight.Medium),
    fontSize: moderateScale(12),
    paddingTop: 5
  },
  errorLabel: {
    color: color.error,
    ...fontWeight(Weight.Medium),
    fontSize: moderateScale(12),
    paddingTop: 5
  }
})
