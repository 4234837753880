import { Dimensions, Platform } from 'react-native'

export const maxWebWidth = 1025
export const maxWebHeight = 900

let { width, height } = Dimensions.get('window')

width = Platform.OS === 'web' ? Math.min(width, maxWebWidth) : width
height = Platform.OS === 'web' ? Math.min(height, maxWebHeight) : height

// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350
const guidelineBaseHeight = 680

const scale = (size: number): number => (width / guidelineBaseWidth) * size
const verticalScale = (size: number): number => (height / guidelineBaseHeight) * size
const moderateScale = (size: number, factor: number = 1.2): number =>
  size + (scale(size) - size) * factor

export { scale, verticalScale, moderateScale }
