import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'

const ShowCharge = () => {
  const paramMatch = window.location.href.match(/id=(.*)$/)
  if (paramMatch && paramMatch[1]) {
    window.location.href = `grana://grana.capital/show-charge?id=${paramMatch[1]}`
  } else {
    window.location.href = 'https://grana.capital'
  }
  return (
    <View style={styles.container}>
      <Image
        style={{
          height: 35,
          aspectRatio: 2.5,
          resizeMode: 'contain',
          marginVertical: 30
        }}
        source={require('../assets/images/mainLogo.png')}
      />
      <Text style={styles.text}>
        Se você estiver usando um dispositivo com o app Grana instalado, será redirecionado para o
        app.
      </Text>
      <Text style={styles.text}>Caso não tenha o app, baixe agora!</Text>
      <View style={styles.badgeContainer}>
        <View style={{ flex: 1 }}>
          <a href="https://apps.apple.com/us/app/grana-ir/id1480394500">
            <Image
              style={{
                height: 35,
                aspectRatio: 2.5,
                resizeMode: 'contain',
                marginTop: 7
              }}
              source={require('../assets/images/app-store-badge.svg')}
            />
          </a>
        </View>
        <View style={{ flex: 1 }}>
          <a href="https://play.google.com/store/apps/details?id=com.investtools.radar">
            <Image
              style={{
                height: 50,
                aspectRatio: 3,
                resizeMode: 'contain'
              }}
              source={require('../assets/images/google-play-badge.png')}
            />
          </a>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    paddingHorizontal: 10
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    marginBottom: 10
  },
  badgeContainer: {
    flexDirection: 'row',
    width: 300,
    alignSelf: 'center',
    marginVertical: 10
  }
})

export default ShowCharge
