const color = {
  error: '#F82063',
  warning: '#f8be20',
  originalBackground: '#2C2F36',
  background: '#33363B',
  backgroundDark: '#191E29',
  backgroundDarkStatusBar: '#273040',
  backgroundGray: '#33363B',
  primary: '#00E676',
  primaryGradient: '#9B5EE1',
  primaryDark: '#0eb564',
  secondary: '#00E676',
  secondaryDark: '#0eb564',
  third: '#00E676',
  thirdDark: '#0eb564',
  inactiveFieldBorder: 'rgba(155, 172, 197, 0.25)',
  switchField: {
    onColor: '#00E676',
    offColor: '#A0A0A0'
  },
  card: {
    background: '#262E3D',
    insideBorder: 'transparent',
    border: '#262E3D',
    label: {
      primary: '#FFFFFF',
      secondary: '#E8E8E8',
      tertiary: '#C7C7C7'
    },
    negative: '#FF7B6C',
    arrow: '#9DB1D0',
    underlayColor: '#262E3D'
  },
  progressBar: {
    container: 'transparent',
    bar: '#00E676'
  },
  disabled: {
    button: '#8D9DB5',
    text: '#6E7B8F',
    placeholder: '#CDD6E2',
    container: 'rgba(155, 172, 197, 0.15)',
    bar: 'rgba(25, 30, 41, 0.5)'
  },
  field: {
    background: '#273040',
    border: 'rgba(0, 0, 0, 0.15)'
  },
  text: {
    dark: '#294B7B',
    gray: '#8D9DB5',
    lightGray: '#c8d1e0',
    touchableButton: '#2C2F36',
    input: '#FAFAFA',
    inputPlaceholder: '#C1C3C8',
    link: '#F5F5F5',
    main: '#FFFFFF'
  },
  header: {
    bullet: '#9A9B9D',
    selectorBackground: '#242c3b',
    background: '#29313f',
    searchContainer: '#1B222E',
    totalValue: '#FFFFFF',
    textInput: '#FFFFFF',
    inputPlaceholder: 'rgba(255, 255, 255, 0.25)',
    title: '#00E676',
    shadow: 'rgba(0,0,0,0.10)'
  }
}

export const fromColorScheme = (colorScheme: string | undefined): string => {
  switch (colorScheme) {
    case 'primary':
      return color.primary
    case 'secondary':
      return color.secondary
    case 'third':
      return color.third
    default:
      return color.primary
  }
}

export default color
