import React, { Component } from 'react'
import Media from 'react-media'
import { Image, View } from 'react-native'

import Label from '../components/Label'
import Screen from '../components/Screen'
import color from '../utils/color'
import { FormValues } from './NewPasswordContainer'
import NewPasswordForm from './NewPasswordForm'

interface Props {
  validate: (values: FormValues) => void
  onSubmit: (values: FormValues) => void
  loading: boolean
  success: boolean
  error: boolean
}

interface MediaMatches {
  small: boolean
  large: boolean
}

class NewPassword extends Component<Props> {
  public render() {
    return (
      <Media queries={{ small: '(max-width: 599px)', large: '(min-width: 600px)' }}>
        {(mediaMatches: MediaMatches) => (
          <Screen style={{ backgroundColor: '#33363B', padding: 0 }}>
            {this.renderHeader(mediaMatches)}
            <div
              style={{
                margin: '0 auto',
                maxWidth: 900,
                zoom: mediaMatches.large ? 0.6 : 1,
                width: mediaMatches.large ? '60%' : '80%'
              }}
            >
              {this.renderContent(mediaMatches)}
              {this.props.error && this.renderError(mediaMatches)}
            </div>
          </Screen>
        )}
      </Media>
    )
  }

  private renderHeader(mediaMatches: MediaMatches) {
    return (
      <View style={{ width: '100%', paddingVertical: mediaMatches.large ? 80 : 40 }}>
        <a href="https://grana.capital/">
          <Image
            style={{
              height: mediaMatches.large ? 60 : 35,
              aspectRatio: 2.5,
              resizeMode: 'contain'
            }}
            source={require('../assets/images/mainLogo.png')}
          />
        </a>
      </View>
    )
  }

  private renderContent(mediaMatches: MediaMatches) {
    if (this.props.success) {
      return this.renderSuccess(mediaMatches)
    } else {
      return <NewPasswordForm {...this.props} />
    }
  }

  private renderSuccess(mediaMatches: MediaMatches) {
    return (
      <View>
        <Label style={{ color: color.primary }}>Senha alterada com sucesso!</Label>
        <br />
        <Label style={{ color: '#fff' }}>
          Acesse o app Grana no seu dispositivo Android ou iOS para se logar usando a nova senha.
        </Label>
      </View>
    )
  }

  private renderError(mediaMatches: MediaMatches) {
    return (
      <View style={{ marginVertical: mediaMatches.large ? 100 : 50 }}>
        <Label style={{ color: color.error }}>
          Ocorreu um erro. Por favor, tente novamente ou entre em contato com o suporte.
        </Label>
      </View>
    )
  }
}

export default NewPassword
