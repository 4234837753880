import AsyncStorage from '@callstack/async-storage'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Action } from 'redux'
import { AxiosAction } from '../utils/authMiddleware'
import { Duck, PayloadAction } from './Duck'

export interface AsyncStorageAction extends Action<string> {
  payload: Promise<string | null | void>
}

export interface ResolvedAsyncStorageAction extends Action<string> {
  payload?: string
}

export interface ResolvedFetchUserAction extends Action<string> {
  payload: AxiosResponse<User>
}

export interface ResolvedAuthAction extends Action<string> {
  payload: AxiosResponse<{ auth_token: string; user: User }>
}

export interface ResolvedUserAuthAction extends Action<string> {
  payload: AxiosResponse<{ auth_token: string; user: User; user_already_exists: boolean }>
}

export interface ResolvedIdDocumentFetchAction extends Action<string> {
  payload: AxiosResponse<{ username: string }>
}

export interface FailedAuthAction extends Action<string> {
  payload: AxiosError<{ errors: { [key: string]: string[] } }>
}

export interface FailedIdDocumentFetchAction extends Action<string> {
  payload: AxiosError<{ errors: { [key: string]: string[] } }>
}

export interface FailedNewPasswordAction extends Action<string> {
  payload: AxiosError<{ errors: { [key: string]: string[] } }>
}

export interface FailedRecoveryAction extends Action<string> {
  payload: AxiosError<{ message: string }>
}

export type AuthAction =
  | ResolvedFetchUserAction
  | ResolvedAuthAction
  | ResolvedIdDocumentFetchAction
  | FailedAuthAction
  | FailedIdDocumentFetchAction
  | ResolvedAsyncStorageAction
  | AxiosAction
  | FailedNewPasswordAction
  | FailedRecoveryAction
  | PayloadAction<string>
  | Action<string>
  | PayloadAction<PasswordStatus>

export interface User {
  id: string
  name: string
  email: string
  param: string
  firebasePassword: string
  integrationStatus: IntegrationStatus
  portfolioHasTransactions?: boolean
  username?: string
}

export type IntegrationStatus =
  | null
  | 'created'
  | 'in_progress'
  | 'completed'
  | 'failed'
  | 'wrong_password'

export interface AuthState {
  token: string
  user: User
  status: Status
  passwordStatus: PasswordStatus
  errors: { [key: string]: string[] }
  postAuthRoute: string | undefined
}

export enum PasswordStatus {
  Idle = 'Idle',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Fullfiled = 'Fullfiled'
}

export enum Status {
  Initialized = 'Initialized',
  TokenSaved = 'TokenSaved',
  TokenLoaded = 'TokenLoaded',
  EmailSent = 'EmailSent',
  SendingEmail = 'SendingEmail',
  EmailNotSent = 'EmailNotSent',
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
  NotAuthenticated = 'NotAuthenticated',
  Authenticating = 'Authenticating',
  Authenticated = 'Authenticated',
  Failed = 'Failed'
}

export class AuthenticationDuck extends Duck<AuthState, AuthAction> {
  private static readonly TOKEN_STORAGE_KEY: string = 'authToken'

  public readonly GET_LOCAL_TOKEN = this.type('GET_LOCAL_TOKEN')
  public readonly GET_LOCAL_TOKEN_FULFILLED = this.type('GET_LOCAL_TOKEN_FULFILLED')
  public readonly GET_LOCAL_TOKEN_REJECTED = this.type('GET_LOCAL_TOKEN_REJECTED')

  public readonly FETCH_ID_DOCUMENT = this.type('FETCH_ID_DOCUMENT')
  public readonly FETCH_ID_DOCUMENT_PENDING = this.type('FETCH_ID_DOCUMENT_PENDING')
  public readonly FETCH_ID_DOCUMENT_FULFILLED = this.type('FETCH_ID_DOCUMENT_FULFILLED')
  public readonly FETCH_ID_DOCUMENT_REJECTED = this.type('FETCH_ID_DOCUMENT_REJECTED')

  public readonly VALIDATE_PASSWORD = this.type('VALIDATE_PASSWORD')
  public readonly VALIDATE_PASSWORD_PENDING = this.type('VALIDATE_PASSWORD_PENDING')
  public readonly VALIDATE_PASSWORD_FULFILLED = this.type('VALIDATE_PASSWORD_FULFILLED')
  public readonly VALIDATE_PASSWORD_REJECTED = this.type('VALIDATE_PASSWORD_REJECTED')

  public readonly UPDATE_INTEGRATION_PASSWORD = this.type('UPDATE_INTEGRATION_PASSWORD')
  public readonly UPDATE_INTEGRATION_PASSWORD_PENDING = this.type(
    'UPDATE_INTEGRATION_PASSWORD_PENDING'
  )
  public readonly UPDATE_INTEGRATION_PASSWORD_FULFILLED = this.type(
    'UPDATE_INTEGRATION_PASSWORD_FULFILLED'
  )
  public readonly UPDATE_INTEGRATION_PASSWORD_REJECTED = this.type(
    'UPDATE_INTEGRATION_PASSWORD_REJECTED'
  )

  public readonly SET_PASSWORD_STATUS = this.type('SET_PASSWORD_STATUS')

  public readonly PERSIST_LOCAL_TOKEN = this.type('PERSIST_LOCAL_TOKEN')
  public readonly PERSIST_LOCAL_TOKEN_FULFILLED = this.type('PERSIST_LOCAL_TOKEN_FULFILLED')
  public readonly PERSIST_LOCAL_TOKEN_REJECTED = this.type('PERSIST_LOCAL_TOKEN_REJECTED')

  public readonly FETCH_USER = this.type('FETCH_USER')
  public readonly FETCH_USER_FULFILLED = this.type('FETCH_USER_FULFILLED')
  public readonly FETCH_USER_REJECTED = this.type('FETCH_USER_REJECTED')

  public readonly PASSWORD_RECOVERY = this.type('PASSWORD_RECOVERY')
  public readonly PASSWORD_RECOVERY_PENDING = this.type('PASSWORD_RECOVERY_PENDING')
  public readonly PASSWORD_RECOVERY_FULFILLED = this.type('PASSWORD_RECOVERY_FULFILLED')
  public readonly PASSWORD_RECOVERY_REJECTED = this.type('PASSWORD_RECOVERY_REJECTED')

  public readonly NEW_PASSWORD = this.type('NEW_PASSWORD')
  public readonly NEW_PASSWORD_PENDING = this.type('NEW_PASSWORD_PENDING')
  public readonly NEW_PASSWORD_FULFILLED = this.type('NEW_PASSWORD_FULFILLED')
  public readonly NEW_PASSWORD_REJECTED = this.type('NEW_PASSWORD_REJECTED')

  public readonly SIGN_IN = this.type('SIGN_IN')
  public readonly SIGN_IN_PENDING = this.type('SIGN_IN_PENDING')
  public readonly SIGN_IN_FULFILLED = this.type('SIGN_IN_FULFILLED')
  public readonly SIGN_IN_REJECTED = this.type('SIGN_IN_REJECTED')

  public readonly SIGN_UP = this.type('SIGN_UP')
  public readonly SIGN_UP_PENDING = this.type('SIGN_UP_PENDING')
  public readonly SIGN_UP_FULFILLED = this.type('SIGN_UP_FULFILLED')
  public readonly SIGN_UP_REJECTED = this.type('SIGN_UP_REJECTED')

  public readonly SIGN_OUT = this.type('SIGN_OUT')
  public readonly SIGN_OUT_FULFILLED = this.type('SIGN_OUT_FULFILLED')

  public readonly SET_POST_AUTH_ROUTE = this.type('SET_POST_AUTH_ROUTE')

  public reducer(state: AuthState, action: AuthAction): AuthState {
    state = super.reducer(state, action)
    switch (action.type) {
      case this.GET_LOCAL_TOKEN_FULFILLED: {
        const token = (action as ResolvedAsyncStorageAction).payload
        return token
          ? { ...state, token, status: Status.TokenLoaded }
          : { ...state, status: Status.NotAuthenticated }
      }
      case this.GET_LOCAL_TOKEN_REJECTED:
        return {
          ...state,
          status: Status.NotAuthenticated
        }

      case this.PERSIST_LOCAL_TOKEN_FULFILLED: {
        return {
          ...state,
          status: Status.TokenSaved
        }
      }

      case this.FETCH_USER_FULFILLED:
        return {
          ...state,
          status: Status.Authenticated,
          user: (action as ResolvedFetchUserAction).payload.data
        }
      case this.FETCH_USER_REJECTED: {
        const response = (action as FailedAuthAction).payload.response
        return {
          ...state,
          status: Status.NotAuthenticated,
          errors: response ? response.data.errors : {}
        }
      }

      case this.SIGN_IN_PENDING:
      case this.SIGN_UP_PENDING:
        return { ...state, status: Status.Authenticating }
      case this.SIGN_IN_FULFILLED:
      case this.SIGN_UP_FULFILLED:
        return {
          ...state,
          status: Status.Authenticated,
          token: (action as ResolvedAuthAction).payload.data.auth_token,
          user: (action as ResolvedAuthAction).payload.data.user
        }
      case this.SIGN_IN_REJECTED:
      case this.SIGN_UP_REJECTED: {
        const response = (action as FailedAuthAction).payload.response
        return {
          ...state,
          status: Status.Failed,
          errors: response ? response.data.errors : {}
        }
      }

      case this.PASSWORD_RECOVERY_PENDING:
        return {
          ...state,
          status: Status.SendingEmail
        }
      case this.PASSWORD_RECOVERY_FULFILLED:
        return {
          ...state,
          status: Status.EmailSent
        }
      case this.PASSWORD_RECOVERY_REJECTED: {
        const response = (action as FailedRecoveryAction).payload.response
        return {
          ...state,
          status: Status.EmailNotSent,
          errors: response ? { passwordRecovery: [response.data.message] } : {}
        }
      }

      case this.NEW_PASSWORD_PENDING:
        return {
          ...state,
          status: Status.Pending
        }
      case this.NEW_PASSWORD_FULFILLED:
        return {
          ...state,
          status: Status.Fulfilled
        }
      case this.NEW_PASSWORD_REJECTED: {
        const response = (action as FailedNewPasswordAction).payload.response
        return {
          ...state,
          status: Status.Rejected,
          errors: response ? response.data.errors : {}
        }
      }

      case this.SIGN_OUT_FULFILLED:
        return {
          ...this.initialState(),
          status: Status.NotAuthenticated
        }
      case this.SET_POST_AUTH_ROUTE:
        return {
          ...state,
          postAuthRoute: (action as PayloadAction<string>).payload
        }

      case this.FETCH_ID_DOCUMENT_PENDING:
        return state
      case this.FETCH_ID_DOCUMENT_REJECTED: {
        const response = (action as FailedIdDocumentFetchAction).payload.response
        console.log(response ? response.data.errors : {})
        return state
      }
      case this.FETCH_ID_DOCUMENT_FULFILLED: {
        const payload = (action as ResolvedIdDocumentFetchAction).payload
        return {
          ...state,
          user: {
            ...state.user,
            username: payload.data.username
          }
        }
      }

      case this.UPDATE_INTEGRATION_PASSWORD_REJECTED: {
        return {
          ...state,
          passwordStatus: PasswordStatus.Rejected
        }
      }
      case this.UPDATE_INTEGRATION_PASSWORD_FULFILLED: {
        return {
          ...state,
          passwordStatus: PasswordStatus.Fullfiled,
          user: {
            ...state.user,
            integrationStatus: 'completed'
          }
        }
      }

      case this.SET_PASSWORD_STATUS: {
        return {
          ...state,
          passwordStatus: (action as PayloadAction<PasswordStatus>).payload
        }
      }

      default:
        return state
    }
  }

  public getLocalStorageToken() {
    return {
      type: this.GET_LOCAL_TOKEN,
      payload: AsyncStorage.getItem(AuthenticationDuck.TOKEN_STORAGE_KEY)
    }
  }

  public persistTokenToLocalStorage(token: string) {
    return {
      type: this.PERSIST_LOCAL_TOKEN,
      payload: AsyncStorage.setItem(AuthenticationDuck.TOKEN_STORAGE_KEY, token)
    }
  }

  public fetchUser(): AxiosAction {
    return {
      type: this.FETCH_USER,
      payload: {
        type: 'AxiosPayload',
        method: 'get',
        path: '/user'
      }
    }
  }

  public fetchIdDocument(): AxiosAction {
    return {
      type: this.FETCH_ID_DOCUMENT,
      payload: {
        type: 'AxiosPayload',
        method: 'get',
        path: '/user_id_document'
      }
    }
  }

  public updateIntegrationPassword(userId: string, password: string): AxiosAction {
    return {
      type: this.UPDATE_INTEGRATION_PASSWORD,
      payload: {
        type: 'AxiosPayload',
        method: 'patch',
        path: '/integration_password',
        data: { userId, password }
      }
    }
  }

  public setPasswordStatus(status: PasswordStatus): PayloadAction<PasswordStatus> {
    return {
      type: this.SET_PASSWORD_STATUS,
      payload: status
    }
  }

  public signIn(email: string, password: string, requestConfig?: AxiosRequestConfig): AxiosAction {
    return {
      type: this.SIGN_IN,
      payload: {
        type: 'AxiosPayload',
        method: 'post',
        path: '/login.json',
        data: { email, password },
        requestConfig
      }
    }
  }

  public passwordRecovery(email: string, requestConfig?: AxiosRequestConfig): AxiosAction {
    return {
      type: this.PASSWORD_RECOVERY,
      payload: {
        type: 'AxiosPayload',
        method: 'post',
        path: 'recuperar-senha',
        data: { password: { email } },
        requestConfig
      }
    }
  }

  public newPassword(
    token: string,
    password: string,
    confirmation: string,
    requestConfig?: AxiosRequestConfig
  ): AxiosAction {
    return {
      type: this.NEW_PASSWORD,
      payload: {
        type: 'AxiosPayload',
        method: 'post',
        path: 'usuarios/senha/editar',
        data: { reset_password_token: token, password, confirmation },
        requestConfig
      }
    }
  }

  public signOut() {
    return {
      type: this.SIGN_OUT,
      payload: AsyncStorage.removeItem(AuthenticationDuck.TOKEN_STORAGE_KEY)
    }
  }

  public setPostAuthRoute(route: string): PayloadAction<string> {
    return {
      type: this.SET_POST_AUTH_ROUTE,
      payload: route
    }
  }

  protected initialState() {
    return {
      status: Status.Initialized,
      passwordStatus: PasswordStatus.Idle
    } as AuthState
  }
  protected store() {
    return 'authentication'
  }
  protected namespace() {
    return 'radar'
  }
}

export const authenticationDuck = new AuthenticationDuck()
