import { AxiosRequestConfig } from 'axios'
import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux'

import server from '../server'
import { ApplicationState } from '../store'

export interface AxiosAction extends Action {
  payload: {
    type: 'AxiosPayload'
    method: AxiosRequestConfig['method']
    path: string
    headers?: {}
    data?: {}
    requestConfig?: AxiosRequestConfig
  }
  meta?: any
}

const authMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
  if (!isAxiosAction(action)) {
    return next(action)
  }

  return next(toPromiseAction(action, api))
}

function isAxiosAction(action: Action): action is AxiosAction {
  return (action as AxiosAction).payload && (action as AxiosAction).payload.type === 'AxiosPayload'
}

const toPromiseAction = (action: AxiosAction, api: MiddlewareAPI) => {
  const { method, path, data, headers, requestConfig } = action.payload
  const extendedHeaders = {
    ...headers,
    Authorization: getAuthToken(api.getState() as ApplicationState)
  }
  return {
    type: action.type,
    payload: server.request({
      method,
      data,
      url: path,
      headers: extendedHeaders,
      ...requestConfig
    }),
    meta: action.meta
  }
}

const getAuthToken = (state: ApplicationState) => state.authentication.token

export default authMiddleware
