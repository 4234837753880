import { FormikErrors } from 'formik'
import React, { Component } from 'react'
import { Alert, Platform, ToastAndroid } from 'react-native'
import { NavigationScreenProp } from 'react-navigation'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { AuthenticationDuck, authenticationDuck, Status } from '../ducks/authenticationDuck'
import { DuckActions } from '../ducks/Duck'
import { ApplicationState } from '../store'
import { emailValidation } from '../utils/formValidation'
import PasswordRecovery from './PasswordRecovery'

const mapStateToProps = (store: ApplicationState) => ({
  status: store.authentication.status,
  errors: store.authentication.errors
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(authenticationDuck.creators(), dispatch)
})

interface Props {
  navigation: NavigationScreenProp<any, any>
  actions: DuckActions<AuthenticationDuck>
  status: Status
  errors: { [k: string]: string[] }
}

interface FormValues {
  email: string
}

interface State {
  loading: boolean
}

class PasswordRecoveryContainer extends Component<Props, State> {
  public static navigationOptions = {
    title: 'Recuperação de senha'
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.status !== Status.EmailSent && this.props.status === Status.EmailSent) {
      this.setState({ loading: false })
      this.handleEmailSent()
    }
    if (prevProps.status !== Status.SendingEmail && this.props.status === Status.SendingEmail) {
      this.setState({ loading: true })
    }
    if (prevProps.status !== Status.EmailNotSent && this.props.status === Status.EmailNotSent) {
      this.setState({ loading: false })
      this.handleErrors()
    }
  }

  public render() {
    return (
      <PasswordRecovery
        onSubmit={this.handleSubmit.bind(this)}
        validate={this.validate.bind(this)}
        loading={this.state.loading}
      />
    )
  }

  private handleSubmit(values: FormValues) {
    this.props.actions.passwordRecovery(values.email)
  }

  private handleEmailSent() {
    const message = 'E-mail de recuperação de senha enviado'
    if (Platform.OS === 'ios') {
      Alert.alert('Recuperação de senha', message)
    } else if (Platform.OS === 'android') {
      ToastAndroid.show(message, ToastAndroid.LONG)
    } else {
      alert(message)
    }
  }

  private handleErrors() {
    const error = this.props.errors && this.props.errors.passwordRecovery
    const message = error ? error[0] : 'Erro ao tentar recuperar a senha'
    if (Platform.OS === 'ios') {
      Alert.alert('Recuperação de senha', message)
    } else if (Platform.OS === 'android') {
      ToastAndroid.show(message, ToastAndroid.LONG)
    } else {
      alert(message)
    }
  }

  private validate(values: FormValues): FormikErrors<FormValues> {
    const errors: FormikErrors<FormValues> = {}

    if (!emailValidation.check(values.email)) {
      errors.email = emailValidation.message
    }

    return errors
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRecoveryContainer)
