import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import NewPasswordContainer from './screens/NewPasswordContainer'
import PasswordRecoveryContainer from './screens/PasswordRecoveryContainer'
import ShowCharge from './screens/ShowCharge'
import store from './store'
import color from './utils/color'
import { config } from './utils/config'
import WebRoutesGenerator from './utils/NativeWebRouteWrapper'

const routeMap = {
  PasswordRecovery: {
    component: PasswordRecoveryContainer,
    path: '/app/auth/recover-password',
    exact: true
  },
  NewPassword: {
    component: NewPasswordContainer,
    path: '/app/auth/new-password',
    exact: true
  },
  ShowCharge: {
    component: ShowCharge,
    path: '/app/show-charge',
    exact: true
  }
}

const ENABLE_ALLOWED_PATHS_ONLY = true
const ALLOWED_PATHS = ['/app/auth/new-password', '/app/show-charge']

const WebContainer = () => (
  <View style={styles.webContainer}>
    <View style={styles.appView}>{WebRoutesGenerator({ routeMap })}</View>
  </View>
)

const redirectToLandingPage = () => {
  if (config('ENV') === 'development') {
    alert(`Versão web está limitada apenas aos paths ${ALLOWED_PATHS}`)
  }
  window.location.href = 'https://grana.capital'
}

class AppRoot extends Component {
  public render() {
    if (ENABLE_ALLOWED_PATHS_ONLY) {
      if (!ALLOWED_PATHS.find(path => window.location.pathname.includes(path))) {
        redirectToLandingPage()
        return null
      }
    }

    return (
      <Provider store={store}>
        <Router>{this.renderWebContainer()}</Router>
      </Provider>
    )
  }

  private renderWebContainer() {
    return <WebContainer />
    // if (ONLY_ALLOW_ONE_PATH) {
    //   return <WebContainer />
    // } else {
    //   return (
    //     <WebAuthenticatedArea>
    //       <WebContainer />
    //     </WebAuthenticatedArea>
    //   )
    // }
  }
}

const vh = 0.01 * window.innerHeight

const styles = StyleSheet.create({
  webContainer: {
    height: 100 * vh,
    width: '100vw',
    justifyContent: 'center',
    backgroundColor: color.background
  },
  appView: {
    marginHorizontal: 'auto',
    width: '100%',
    height: '100%'
  }
})

export default AppRoot
