import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import React, { Component } from 'react'
import { Platform, StyleSheet, TextInput, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'

import color from '../utils/color'
import font, { Weight } from '../utils/font'
import { moderateScale } from '../utils/scaler'
import CurrencyTextInput from './CurrencyTextInput'
import TextInputMask from './TextInputMask'
import { FieldProps, InjectedProps, withField } from './withField'

interface Props extends FieldProps, InjectedProps {
  value: string
  currency?: boolean
  alternative?: boolean
  placeholder: string
  maxLength: number
  alpha?: boolean
  mask?: 'cpf' | 'cpf-cnpj' | 'date' | 'currency' | 'cep'
  textType?: 'text' | 'email' | 'password'
  keyboardType?: 'default' | 'email-address' | 'numeric'
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined
  onChangeText: (text: string, textRaw?: any) => void
  setActive: (active: boolean) => void
  onBlur?: () => void
  onFocus?: () => void
  testID?: string
}

interface State {
  displayPasswordText: boolean
}

class TextField extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { displayPasswordText: false }
  }

  public render() {
    return (
      <View style={this.containerStyle()}>
        {this.renderInput()}
        {this.renderDisplayPasswordText()}
      </View>
    )
  }

  private renderInput(): React.ReactNode {
    const sharedProps = {
      style: this.inputStyles(),
      value: this.props.value,
      placeholder: this.props.placeholder || '***',
      placeholderTextColor: this.placeholderTextColor(),
      editable: !this.props.disabled,
      maxLength: this.props.maxLength || 18,
      secureTextEntry: this.shouldSecureText(),
      onChangeText: this.handleChangeText.bind(this),
      textContentType: this.getTextContentType(),
      keyboardType: this.getKeyboardType(),
      onFocus: () => this.onFocus(),
      onBlur: () => this.onBlur(),
    }
    if (this.props.mask) {
      return (
        <TextInputMask
          {...sharedProps}
          type={'custom'}
          options={{
            mask: this.mask(),
            getRawValue: this.getRawValue.bind(this)
          }}
          includeRawValueInChangeText={true}
        />
      )
    } else if (this.props.currency) {
      return <CurrencyTextInput {...sharedProps} />
    } else {
      return (
        <TextInput
          autoCorrect={this.getTextContentType() === 'password' ? false : true}
          autoCapitalize={this.props.autoCapitalize || 'none'}
          {...sharedProps}
        />
      )
    }
  }

  private renderDisplayPasswordText() {
    if (this.props.textType === 'password') {
      return (
        <View
          style={{
            position: 'absolute',
            right: 20,
            bottom: 5
          }}
        >
          <TouchableOpacity onPress={this.toggleDisplayPasswordText.bind(this)}>
            <FontAwesomeIcon
              icon={this.state.displayPasswordText ? faEyeSlash : faEye}
              size={moderateScale(23)}
              color={this.props.active ? color.primary : color.disabled.text}
            />
          </TouchableOpacity>
        </View>
      )
    } else {
      return null
    }
  }

  private onFocus() {
    this.props.setActive(true)
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }

  private onBlur() {
    this.props.setActive(false)
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  private shouldSecureText() {
    return this.props.textType === 'password' && !this.state.displayPasswordText
  }

  private toggleDisplayPasswordText() {
    this.setState(prevState => ({
      displayPasswordText: !prevState.displayPasswordText
    }))
  }

  private handleChangeText(value: string, rawValue?: string) {
    if (this.props.alpha && !this.hasOnlyLettersAndSpaces(value)) {
      return
    }
    this.props.setActive(value !== '')
    this.props.onChangeText(value, rawValue)
  }

  private hasOnlyLettersAndSpaces(value: string): boolean {
    return !!value.match(/^[A-Za-z\s]*$/)
  }

  private mask(): string {
    switch (this.props.mask) {
      case 'cpf':
        return '999.999.999-99'
      case 'cpf-cnpj':
        if (this.props.value.length <= 14) {
          return '999.999.999-999'
        } else {
          return '99.999.999/999-99'
        }
      case 'date':
        return '99/99/9999'
      case 'currency':
        return '9,999,999,999.99'
      case 'cep':
        return '99999-999'
      default:
        return ''
    }
  }

  private getRawValue(value: string) {
    if (this.props.mask === 'cpf-cnpj') {
      return value.replace(/\.|-/g, '')
    }

    return value
  }

  private getTextContentType(): any {
    const { textType } = this.props
    return textType === 'email' ? 'emailAddress' : textType === 'password' ? 'password' : 'none'
  }

  private getKeyboardType(): any {
    const { keyboardType } = this.props
    return keyboardType ? keyboardType : 'default'
  }

  private containerStyle() {
    const styleCollection: ViewStyle[] = [styles.container]

    if (this.props.disabled) {
      styleCollection.push(styles.containerDisabled)
    }

    return StyleSheet.flatten(styleCollection)
  }

  private inputStyles() {
    const styleCollection: TextStyle[] = [styles.input]

    if (this.props.active) {
      styleCollection.push(styles.inputActive)
    }
    if (this.props.errorTipVisible) {
      styleCollection.push(styles.inputError)
    }
    if (this.props.disabled) {
      styleCollection.push(styles.inputDisabled)
    }

    return StyleSheet.flatten(styleCollection)
  }

  private placeholderTextColor() {
    if (this.props.disabled) {
      return color.disabled.placeholder
    }

    return color.text.gray
  }
}

const inputVerticalMargin = () => {
  switch (Platform.OS) {
    case 'ios':
      return '1%'
    case 'android':
      return -moderateScale(9)
    default:
      return 5
  }
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 2,
    width: '100%'
  },
  containerDisabled: {
    backgroundColor: color.disabled.container
  },
  input: {
    color: color.text.input,
    ...font('Roboto', Weight.Regular),
    fontSize: Platform.OS === 'ios' ? moderateScale(14) : moderateScale(12),
    marginLeft: 10,
    marginVertical: inputVerticalMargin(),
    paddingVertical: Platform.OS === 'ios' ? 5 : 13
  },
  inputActive: {
    color: color.text.input,
    borderBottomColor: color.primary
  },
  inputError: {
    color: color.error
  },
  inputDisabled: {
    color: color.disabled.placeholder
  }
})

export default withField<Props>(TextField)
