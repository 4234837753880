import React from 'react'
import { TextInput, TextStyle, View } from 'react-native'

interface Props {
  style: TextStyle
  value: string
  placeholder?: string
  placeholderTextColor: string
  editable: boolean
  maxLength?: number
  secureTextEntry: boolean
  onChangeText: (value: string, valueRaw?: any) => void
  keyboardType: 'default' | 'email-address' | 'numeric'
  onFocus: () => void
  onBlur: () => void
}

const CurrencyTextInput = (props: Props): JSX.Element => {
  const maskText = (text: string) => {
    if (text === '') {
      return text
    }
    let asNumber = parseInt(text, 10)
    if (isNaN(asNumber)) {
      asNumber = 0
    }
    const transformed = formatter(asNumber / 100)

    return transformed
  }

  const formatter = (currency: number): string => {
    return (
      'R$ ' +
      currency
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    )
  }

  const unMaskText = (text: string) => {
    return text.replace(/,|\.|R\$|\s/g, '')
  }

  const onChangeText = (text: string) => {
    const unmaskedText = unMaskText(text)
    props.onChangeText(unmaskedText, parseInt(unmaskedText, 10) / 100)
  }

  return (
    <View>
      <TextInput
        {...props}
        value={maskText(props.value)}
        onChangeText={onChangeText}
        placeholder={props.placeholder}
      />
    </View>
  )
}

export default CurrencyTextInput
