import React, { ChangeEvent, Component } from 'react'
import InputMask from 'react-input-mask'
import { TextStyle } from 'react-native'

import color from '../utils/color'
import { moderateScale } from '../utils/scaler'

interface Props {
  style: TextStyle
  value: string
  placeholder: string
  placeholderTextColor: string
  editable: boolean
  maxLength: number
  secureTextEntry: boolean
  onChangeText: (value: string, valueRaw?: string) => void
  textContentType: string
  keyboardType: string
  onFocus: () => void
  onBlur: () => void
  type: string
  options: {
    mask: string
    getRawValue: (value: string) => string
  }
  includeRawValueInChangeText: boolean
}
class TextInputMask extends Component<Props> {
  public render() {
    return (
      <InputMask
        value={this.props.value}
        style={{
          backgroundColor: color.background,
          color: color.text.input,
          border: 'none',
          paddingLeft: 15,
          paddingTop: 10,
          paddingBottom: 2,
          fontSize: moderateScale(12)
        }}
        mask={this.props.options.mask}
        onChange={this.onChange.bind(this)}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
      />
    )
  }

  private onChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    this.props.onChangeText(value, this.props.options.getRawValue(value))
  }
}
export default TextInputMask
